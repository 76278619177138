import $ from "jquery";
import "what-input";

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require("foundation-sites");

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

// Adds phone number validation pattern to Foundation Abide defaults
Foundation.Abide.defaults.patterns["tel"] = /^\d{10}$/;

$(document).foundation();

/* ACTIVE NAV ITEM */
$(document).ready(function () {
  // Strip characters
  let url = window.location.href;
  url = url.substring(
    0,
    url.indexOf("#") === -1 ? url.length : url.indexOf("#")
  );
  url = url.substring(
    0,
    url.indexOf("?") === -1 ? url.length : url.indexOf("?")
  );
  url = url.substr(url.lastIndexOf("/") + 1);

  // We need a value for when url is blank
  if (url === "") {
    url = "index.html";
  }

  // Check href values for each li in #apprise-header
  // If href matches url, set the active class to the li
  $("#apprise-header li").each(function () {
    let href = $(this).find("a").attr("href");
    if (url === href) {
      $(this).addClass("active");
    }
  });

  // "Slick" Slider/Carousel
  $(".block_slider").slick({
    autoplay: false,
    autoplaySpeed: 5000,
    dots: true,
    speed: 500,
    infinite: true,
    slidesToShow: 1,
    arrows: true,
    cssEase: "linear",
    prevArrow: $(".slider-arrow--prev"),
    nextArrow: $(".slider-arrow--next"),
    adaptiveHeight: false,
  });

  // Coverage Map

  var $mapToolTip = $(".map-tooltip");

  var $southeastRegion = $(".southeast");
  var $southwestRegion = $(".southwest");
  var $midwestRegion = $(".midwest");
  var $mountainRegion = $(".mountain");
  var $northeastRegion = $(".northeast");
  var $westcoastRegion = $(".westcoast");

  var $southwestRegionContent =
    '<div class="region-rep"><div class="region-rep--cta bg--southwest">Connect with your <br>Southwest Region Appraiser</div><div class="grid-x"><div class="cell small-3"><img width="50" src="/static/dist/img/tooltip-sid-womack.png" alt="Sid Womack, MAI"></div><div class="cell small-9"><h3>Sid Womack, MAI</h3><p>Managing Director, Southwest Region</p></div></div></div>';
  var $southeastRegionContent =
    '<div class="region-rep"><div class="region-rep--cta bg--southeast ">Connect with your <br>Southeast Region Appraiser</div><div class="grid-x"><div class="cell small-3"><img width="50" src="/static/dist/img/tooltip-amy-blackman.png" alt="Amy Blackman, MAI"></div><div class="cell small-9"><h3>Amy Blackman, MAI</h3><p>Managing Director, Southeast Region</p></div></div></div>';
  var $midwestRegionContent =
    '<div class="region-rep"><div class="region-rep--cta bg--midwest">Connect with your <br>Midwest Region Appraiser</div><div class="grid-x"><div class="cell small-3"><img width="50" src="/static/dist/img/tooltip-meghan-czechowski.png" alt="Meghan Czechowski, MAI"></div><div class="cell small-9"><h3>Meghan Czechowski, MAI</h3><p>Managing Director, Midwest Region</p></div></div></div>';
  var $mountainRegionContent =
    '<div class="region-rep"><div class="region-rep--cta bg--mountain">Connect with your <br>Mountain Region Appraiser</div><div class="grid-x"><div class="cell small-3"><img width="50" src="/static/dist/img/tooltip-kelly-hyde.png" alt="Kelly Hyde"></div><div class="cell small-9"><h3>Kelly Hyde, MAI</h3><p>Senior Director, Northeast Region</p></div></div></div>';
  var $northeastRegionContent =
    '<div class="region-rep"><div class="region-rep--cta bg--northeast">Connect with your <br>Northeast Region Appraiser</div><div class="grid-x"><div class="cell small-3"><img width="50" src="/static/dist/img/tooltip-corey.png" alt="Corey Gustafson"></div><div class="cell small-9"><h3>Corey Gustafson, MAI</h3><p>Managing Director, Northeast Region</p></div></div></div>';
  var $westcoastRegionContent =
    '<div class="region-rep"><div class="region-rep--cta bg--westcoast">Connect with your <br>West Coast Region Appraiser</div><div class="grid-x"><div class="cell small-3"><img width="50" src="/static/dist/img/tooltip-jacinto.png" alt="Jacinto Munoz"></div><div class="cell small-9"><h3>Jacinto Munoz, MAI</h3><p>Managing Director, West Coast Region</p></div></div></div>';

  $northeastRegion.hover(
    function () {
      $(this).addClass("pathfill-northeast-hover");
      $mapToolTip.addClass("active").html($northeastRegionContent);
    },
    function () {
      $(this).removeClass("pathfill-northeast-hover");
      $mapToolTip.removeClass("active");
    }
  );

  $southeastRegion.hover(
    function () {
      $(this).addClass("pathfill-southeast-hover");
      $mapToolTip.addClass("active").html($southeastRegionContent);
    },
    function () {
      $(this).removeClass("pathfill-southeast-hover");
      $mapToolTip.removeClass("active");
    }
  );

  $southwestRegion.hover(
    function () {
      $(this).addClass("pathfill-southwest-hover");
      $mapToolTip.addClass("active").html($southwestRegionContent);
    },
    function () {
      $(this).removeClass("pathfill-southwest-hover");
      $mapToolTip.removeClass("active");
    }
  );

  $midwestRegion.hover(
    function () {
      $(this).addClass("pathfill-midwest-hover");
      $mapToolTip.addClass("active").html($midwestRegionContent);
    },
    function () {
      $(this).removeClass("pathfill-midwest-hover");
      $mapToolTip.removeClass("active");
    }
  );

  $mountainRegion.hover(
    function () {
      $(this).addClass("pathfill-mountain-hover");
      $mapToolTip.addClass("active").html($mountainRegionContent);
    },
    function () {
      $(this).removeClass("pathfill-mountain-hover");
      $mapToolTip.removeClass("active");
    }
  );

  $westcoastRegion.hover(
    function () {
      $(this).addClass("pathfill-westcoast-hover");
      $mapToolTip.addClass("active").html($westcoastRegionContent);
    },
    function () {
      $(this).removeClass("pathfill-westcoast-hover");
      $mapToolTip.removeClass("active");
    }
  );

  $(document).on("mousemove", function (e) {
    $mapToolTip.css({
      left: e.pageX + 20,
      top: e.pageY - 40,
    });
  });
});

/* MOBILE NAV */
$(document)
  .ready(function () {
    console.log("ready");
  })
  .on("click", "#nav-toggle", function (e) {
    e.preventDefault();
    $("body").toggleClass("open-navigation");
    if ($("body").hasClass("open-navigation")) {
      $("#nav-toggle").attr("aria-expanded", "true");
    } else {
      $("#nav-toggle").attr("aria-expanded", "false");
    }
  })
  .on("focusin", function (e) {
    if (!$(e.target).hasClass("navitem")) {
      $("body").removeClass("open-navigation");
    }
  })

  /* SOCIAL SHARE */
  .on("click", "#shareFacebook", function () {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=" +
        encodeURIComponent(document.URL) +
        "&t=" +
        encodeURIComponent(document.URL)
    );
    return false;
  })

  .on("click", "#shareTwitter", function () {
    window.open(
      "https://twitter.com/intent/tweet?text=" +
        encodeURIComponent(document.title) +
        ":%20 " +
        encodeURIComponent(document.URL)
    );
    return false;
  })

  .on("click", "#shareLinkedIn", function () {
    window.open(
      "http://www.linkedin.com/shareArticle?mini=true&url=" +
        encodeURIComponent(document.URL) +
        "&title=" +
        encodeURIComponent(document.title)
    );
    return false;
  })

  .on("click", "#shareEmail", function () {
    window.open(
      "mailto:?subject=" +
        encodeURIComponent(document.title) +
        "&body=" +
        encodeURIComponent(document.URL)
    );
    return false;
  });
